<template>
	<div class="main">
		<div class="top">
			<p><span @click="$router.go(-1)">收益提现</span> / <span>提现记录</span></p>
			<span>查看近期收益状况并提现到账户</span>
		</div>
		<div class="content">
			<p class="p">
				<span>提现记录</span>
			</p>
			<el-table :data="data" v-loading="loading">
				<el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
				<el-table-column prop="cardName" label="银行卡户主" align="center"></el-table-column>
				<el-table-column prop="bankName" label="开户银行" align="center"></el-table-column>
				<el-table-column prop="cardNum" label="银行卡号" align="center"></el-table-column>
				<el-table-column prop="num" label="提现金额" align="center">
					<template slot-scope="scope">
						<span>{{$util.setMoney(scope.row.num)}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="提现日期" align="center"></el-table-column>
				<el-table-column prop="status" label="审核状态" align="center" width="80"></el-table-column>
			</el-table>
			<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
				:current-page="currpage" :page-sizes="[10, 50, 100]" :page-size="pagesize" background
				layout="total,prev, pager, next,sizes,jumper" :total="CountNum"
				style="margin-top:10px;text-align: center;">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		agentadvprofitextract
	} from '@/api/agentApi.js'
	export default {
		data() {
			return {
				data: [], //表格数据
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				loading: false, //加载框
			}
		},
		mounted() {
			this.agentadvprofitextract(1, this.pagesize) //获取提现记录列表
		},
		methods: {
			agentadvprofitextract(currpage, pageSize) { //获取提现记录列表
				this.loading = true
				const data = {}
				data["page"] = currpage //当前页数
				data["limit"] = pageSize //每页条数
				agentadvprofitextract(data).then(res => {
					this.data = res.data.List //提现记录列表
					this.CountNum = parseInt(res.data.CountNum) //总条数
					this.currpage = currpage //分页显示当前页
					this.pagesize = pageSize //每页显示条数
					this.loading = false
				})
			},
			handleCurrentChange(num) { //分页
				this.agentadvprofitextract(num, this.pagesize)
			},
			handleSizeChange(num) { //分页设置显示条数
				this.agentadvprofitextract(1, num)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.main {
		background-color: #f0f2f5;
	}

	.top {
		width: 100%;
		height: 10vh;
		text-align: left;
		padding-left: 4%;
		background-color: white;
		margin-bottom: 3vh;

		span {
			font-size: 14px;
			color: gray;
		}

		p {
			font-size: 20px;
			color: #97a8be;

			span:nth-child(1) {
				font-size: 20px;
				color: burlywood;
				cursor: pointer;
			}

			span:nth-child(2) {
				font-size: 20px;
				color: #97a8be;
			}
		}
	}

	.content {
		background-color: white;
		width: 96%;
		margin: 0 auto;
		text-align: left;

		.p {
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #E8E8E8;
			color: #1890FF;

			span {
				margin-left: 10px;
				height: 40px;
				line-height: 40px;
				border-bottom: 2px solid #1890FF;
				display: inline-block;
			}
		}
	}
</style>
